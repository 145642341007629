import { useApolloClient, gql, TypedDocumentNode } from '@apollo/client'
import productCardFragment from '@emico-hooks/product-card-fragment/src/ProductCardFragment'
import { useCallback, useState } from 'react'

import {
  ProductsBySkuOrNameQuery,
  ProductsBySkuOrNameQueryVariables,
} from './useProductsBySkuOrName.generated'

const productsBySkuOrNameQuery = gql`
  query ProductsBySkuOrName($search: String!, $searchtype: String) {
    productsBySkuOrName(search: $search, searchtype: $searchtype) {
      product {
        ...ProductCardFragment
      }
      year
    }
  }

  ${productCardFragment}
` as TypedDocumentNode<
  ProductsBySkuOrNameQuery,
  ProductsBySkuOrNameQueryVariables
>

/**
 * Query to fetch products by a product sku or name
 *
 * Usage example:
 *
 * ```ts
 * export function exampleFunction() {
 *  const { handleSubmit, hasError, data } = useProductsBySkuOrName()
 * }
 * ```
 */

export function useProductsBySkuOrName() {
  const client = useApolloClient()
  const [data, setData] = useState<ProductsBySkuOrNameQuery>()
  const [hasError, setHasError] = useState(false)

  const submit = useCallback(
    /**
     * @param search Sku or name of the product
     */
    async (search: string, searchType: string) => {
      setHasError(false)
      setData(undefined)

      try {
        const { data: productsBySkuOrNameData } = await client.query({
          query: productsBySkuOrNameQuery,
          variables: {
            search,
            searchtype: searchType,
          },
        })

        setData(productsBySkuOrNameData)
      } catch (error) {
        setHasError(true)
      }
    },
    [client],
  )

  return {
    submit,
    hasError,
    data: data?.productsBySkuOrName,
  }
}
