import styled from '@emotion/styled'

import { minWidth } from '@emico/styles'

import theme from '../theme'

const Block = styled.div`
  margin-top: ${theme.spacing.xl};
  padding: ${theme.spacing.md};
  border: ${theme.borders.base} ${theme.colors.red};
  border-radius: ${theme.borderRadius.base};
  background: ${theme.colors.redTransparent};

  @media ${minWidth('md')} {
    padding: ${theme.spacing.lg};
  }
`

const Title = styled.div`
  font-weight: ${theme.fontWeights.bold};
  text-transform: uppercase;
`

const StyledUl = styled.ul`
  margin: ${theme.spacing.sm} 0 0 0;
`

interface Props {
  title: string
  listItems?: string[]
}

export const ErrorBlock = ({ title, listItems }: Props) => (
  <Block>
    <Title>{title}</Title>

    {listItems ? (
      <StyledUl>
        {listItems.map((listItem, index) => (
          <li key={index}>{listItem}</li>
        ))}
      </StyledUl>
    ) : null}
  </Block>
)
