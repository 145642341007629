import styled from '@emotion/styled'
import { t, Trans } from '@lingui/macro'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { minWidth } from '@emico/styles'
import { Loader } from '@emico/ui'

import { useProductsBySkuOrName } from '../lib/useProductsBySkuOrName'
import theme from '../theme'
import ButtonPrimary from './ButtonPrimary'
import { ErrorBlock } from './ErrorBlock'
import Input from './Input'

const StyledButtonPrimary = styled(ButtonPrimary)`
  margin-top: ${theme.spacing.lg};

  @media ${minWidth('md')} {
    min-width: 186px;
  }
`

interface FormValues {
  search: string
  searchType: string
}

interface Props {
  searchType: 'spareparts' | 'registration'
  onSuccessRoutePathName: string
  className?: string
}

const ProductsBySkuOrNameForm = ({
  searchType,
  className,
  onSuccessRoutePathName,
}: Props) => {
  const router = useRouter()
  const [loading, setLoading] = useState(false)
  const { submit, hasError, data } = useProductsBySkuOrName()
  const { register, control, handleSubmit, watch, formState } =
    useForm<FormValues>({
      defaultValues: {
        searchType,
      },
    })

  const searchValue = watch('search')

  const errorMessages = {
    required: t({
      message: 'This field is required',
    }),
  }

  const onSubmit = async (formValues: FormValues) => {
    setLoading(true)
    await submit(formValues.search, formValues.searchType ?? '')
    setLoading(false)
  }

  useEffect(() => {
    if (data && data?.length > 0) {
      router.push({
        pathname: onSuccessRoutePathName,
        query: {
          search: searchValue,
        },
      })
    }
  }, [data, router, searchValue, onSuccessRoutePathName])

  return (
    <>
      <form className={className} onSubmit={handleSubmit(onSubmit)}>
        <input type="hidden" {...register('searchType')} />
        <Input
          control={control}
          label={t({
            message: 'Search product',
          })}
          {...register('search', {
            required: {
              value: true,
              message: errorMessages.required,
            },
          })}
        />

        <StyledButtonPrimary
          analyticsContext="products"
          analyticsName="search.by.sku.or.name"
          colorType="neutral"
          disabled={!formState.isValid}
        >
          <Trans>Search</Trans>
        </StyledButtonPrimary>
      </form>

      {hasError ? (
        <ErrorBlock
          title={t({
            message: 'Could not fetch products, please try again later.',
          })}
        />
      ) : null}

      {data?.length === 0 ? (
        <ErrorBlock
          title={t({ message: 'Sorry, we could not find products' })}
          listItems={[
            t({
              message:
                'Search using the full sku number of the original product (e.g. 24.45.00.01)',
            }),
            t({
              message:
                'Try to search by name, such as BERG Buddy or BERG Champion InGround',
            }),
          ]}
        />
      ) : null}

      {loading && <Loader />}
    </>
  )
}

export default ProductsBySkuOrNameForm
